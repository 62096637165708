import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import Tabs from "src/components/generic/Tabs/Tabs";

// Import videos
import queryVid from "static/images/pages/how_does_it_work/query_20.mp4";
import changingSource from "static/images/pages/homepage/search_console_changing_source.mp4";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "On the spot analysis",
	subTitle: (
		<p>
			Now the data is loaded and we&apos;ve got best in class reports.
			What about ad-hoc analysis?
		</p>
	)
};

const alternatingContent = [
	{
		subTitle:
			"Reports don't cover every case. Sometimes you need to get hands on.",
		content: (
			<React.Fragment>
				<p>
					Sometimes you need to get hands on with the data to get your
					answer. We make it easy.
				</p>
				<ul>
					<li>You open our query engine.</li>
					<li>Select a pre-built query (e.g. cannibalisation).</li>
					<li>
						Select the Search Console properties & the date range
						you want
					</li>
					<li>Hit the copy button and paste it into BigQuery</li>
				</ul>
				<p>
					All the data heavy lifting done in under a minute, ready for
					pulling into a spreadsheet and working through it.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Making your own queries",
		content: (
			<React.Fragment>
				<p>
					We&apos;ve got prewritten queries for many common SEO tasks,
					but you might also want to make your own.
				</p>
				<p>
					With our query building/editing you can create the custom
					queries you want.
				</p>
				<p>
					We complex filtering logic to make sure you can select the
					data segments you need to work with.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Get the data wherever you need",
		content: (
			<React.Fragment>
				<p>
					Once you&apos;ve run a query, the data can easily be moved
					wherever you need it.
				</p>
				<p>
					You could pull it into a Google Data Studio or Tableau
					report, or just download it straight into a spreadsheet.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Repeating analysis",
		content: (
			<React.Fragment>
				<p>
					Working with data in spreadsheets has it&apos;s issues. One
					of the largest ones is repetition.
				</p>
				<p>
					Repeating analysis is slow and fiddly. You know that awful
					feeling, when you realise you&apos;ve been working on the
					wrong data segment and now you have to repeat all your work
					again?
				</p>
				<p>
					With Piped Out repeating analysis with different time
					periods or data sources is as simple as changing a form.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired,
	location: PropTypes.shape().isRequired
};

const Index = ({ data, location }) => {
	// Set images
	alternatingContent[0].video = queryVid;
	alternatingContent[1].image = data.alternatingImg2.childImageSharp.fluid;
	alternatingContent[2].image = data.alternatingImg1.childImageSharp.fluid;
	alternatingContent[3].video = changingSource;

	const title = "Working with data in the warehouse";
	const description =
		"Now the data is loaded, we&apos;ve got huge amounts of data at our fingertips. We need working with it to be quick and painless, so we can get to problem solving and doing our jobs.";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={false}
				isWhite={true}
			/>
			<section>
				{/* <StripTitle title="How does analysing data work?" /> */}
				<StripsAlternatingContent
					stripList={alternatingContent}
					classes="m-t-lg"
				/>
			</section>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query QueryingData {
		# Get us the main alternating images
		alternatingImg1: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/save_query_results.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 681, maxHeight: 632) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg2: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/query_builder.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 916, maxHeight: 705) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
